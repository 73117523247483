import {Link} from "gatsby";
import React from "react";

import {Card, Elevation, Tag, H2, H5, Icon} from "@blueprintjs/core";

const ArticleCard = ({title, post, date, tags}) => {
    return (
        <Link to={post.fields.slug} itemProp="url">
            <Card interactive={true} elevation={Elevation.TWO}>
                <H2>
                    <span itemProp="headline">{title}</span>
                </H2>
                <H5>{date}</H5>
                <p>{post.frontmatter.description || post.excerpt}</p>
                <Icon icon="tag" intent={"Success"}/>{tags.map(tag => (<Tag key={tag} round={true}>{tag}</Tag>))}
            </Card>
        </Link>
    )
}

export default ArticleCard