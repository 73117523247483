import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import ArticleCard from "../components/ArticleCard";

const BlogIndex = ({ data, location }) => {
    const siteTitle = "@rt_upintheair's blog"
    const posts = data.allMarkdownRemark.nodes

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="All @rt_upintheair's blog posts" />
                <Bio />
                <p>
                    No blog posts found.
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo Title="All @rt_upintheair's blog posts" />
            <ol style={{ listStyle: `none` }}>
                {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug

                    return (
                        <li key={post.fields.slug}>
                            <ArticleCard title={title} post={post} date={post.frontmatter.date} tags={post.frontmatter.tags}/>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD (ddd)")
          title
          description
          tags
        }
      }
    }
  }
`
